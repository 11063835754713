import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Manages global loading state.  */
@Injectable({ providedIn: 'root' })
export class LoadingService {

	/** Whether something is loading or not in the app. */
	public readonly isLoading$ = new BehaviorSubject(false);
}
